import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 600.000000 600.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,600.000000) scale(0.100000,-0.100000)">
<path d="M2899 4256 c-2 -3 -276 -89 -609 -191 -332 -102 -612 -191 -622 -196
-22 -12 -23 -32 -4 -52 13 -13 62 0 383 100 202 62 478 148 611 189 134 42
250 73 258 70 7 -3 22 -34 31 -68 10 -35 38 -126 63 -203 37 -112 50 -141 66
-143 10 -1 25 2 33 7 13 8 6 40 -43 198 -91 292 -91 293 -130 293 -18 0 -35
-2 -37 -4z"/>
<path d="M1860 3690 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"/>
<path d="M1674 3678 c8 -4 25 -4 38 1 20 8 18 9 -15 8 -24 -1 -32 -4 -23 -9z"/>
<path d="M1915 3680 c-3 -6 -1 -10 5 -10 28 0 120 -55 166 -99 65 -63 94 -96
94 -110 0 -6 5 -11 10 -11 17 0 11 16 -17 46 -16 16 -32 38 -38 49 -6 11 -22
25 -35 32 -14 7 -21 13 -16 13 15 0 -37 37 -84 60 -25 12 -53 26 -62 31 -10 5
-19 5 -23 -1z"/>
<path d="M1595 3656 c-54 -23 -106 -64 -94 -72 7 -4 17 -1 23 7 16 21 66 55
101 67 17 6 23 11 15 11 -8 0 -28 -6 -45 -13z"/>
<path d="M1460 3550 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1433 3531 c-6 -10 -8 -23 -4 -29 4 -7 10 -5 18 7 6 10 8 23 4 29 -4
7 -10 5 -18 -7z"/>
<path d="M1405 3491 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M1385 3439 c3 -3 -2 -12 -10 -19 -18 -15 -20 -31 -2 -25 19 8 37 52
21 52 -7 0 -11 -4 -9 -8z"/>
<path d="M2203 3408 c-1 -18 1 -27 4 -20 3 6 9 9 15 6 8 -5 -5 29 -17 44 -1 1
-2 -12 -2 -30z"/>
<path d="M2375 3398 c-5 -19 -45 -252 -45 -267 0 -8 11 -11 33 -9 29 3 33 7
41 44 8 41 9 42 58 46 69 7 97 37 105 109 8 78 -5 89 -108 89 -55 0 -81 -4
-84 -12z m123 -74 c4 -26 -22 -54 -49 -54 -15 0 -19 7 -19 33 0 41 7 49 40 45
17 -2 26 -10 28 -24z"/>
<path d="M2606 3279 c-25 -72 -46 -137 -46 -145 0 -9 11 -14 31 -14 25 0 32 6
45 35 12 29 19 35 44 35 28 0 30 -3 30 -35 0 -33 2 -35 35 -35 l35 0 0 145 0
145 -64 0 -64 0 -46 -131z m114 34 c0 -48 -11 -73 -31 -73 -22 0 -24 19 -7 67
15 40 38 44 38 6z"/>
<path d="M2890 3397 c-27 -14 -50 -51 -50 -83 0 -29 34 -61 79 -75 60 -18 68
-59 11 -59 -20 0 -31 6 -35 21 -5 16 -14 19 -38 17 -26 -2 -33 -8 -35 -30 -5
-43 31 -68 99 -68 76 0 119 32 119 89 0 43 -17 63 -72 80 -20 7 -41 18 -47 24
-16 20 3 39 35 35 21 -2 30 -9 32 -25 2 -16 10 -23 26 -23 47 0 62 54 26 90
-24 24 -109 28 -150 7z"/>
<path d="M3145 3398 c-32 -18 -44 -40 -45 -79 0 -42 14 -57 74 -79 29 -11 41
-21 41 -35 0 -29 -50 -33 -65 -6 -7 14 -21 21 -40 21 -27 0 -30 -3 -30 -34 0
-45 29 -66 92 -66 68 0 97 14 114 56 23 55 6 87 -59 111 -39 14 -52 23 -52 38
0 15 8 21 33 23 26 3 32 0 32 -17 0 -26 15 -34 46 -27 19 5 24 13 24 40 0 48
-25 66 -91 66 -30 0 -63 -6 -74 -12z"/>
<path d="M3351 3278 c-12 -73 -21 -139 -21 -146 0 -9 11 -12 33 -10 l32 3 22
125 c29 167 30 160 -10 160 l-34 0 -22 -132z"/>
<path d="M3540 3403 c-41 -17 -67 -75 -76 -171 -6 -63 -5 -71 17 -90 41 -39
152 -23 178 27 15 28 32 108 33 159 1 59 -25 82 -91 81 -25 0 -53 -3 -61 -6z
m79 -83 c1 -14 -2 -49 -9 -77 -9 -43 -16 -54 -35 -59 -40 -10 -48 3 -41 65 4
30 14 66 22 79 20 31 58 26 63 -8z"/>
<path d="M3755 3388 c-14 -64 -42 -254 -38 -260 2 -5 17 -8 33 -8 30 0 33 5
45 93 4 26 10 47 14 47 3 0 15 -30 27 -67 19 -66 21 -68 55 -71 l35 -3 17 98
c10 54 20 119 23 146 l7 47 -36 0 -35 0 -7 -60 c-4 -33 -10 -62 -15 -65 -4 -3
-16 24 -26 60 l-19 65 -37 0 c-31 0 -39 -4 -43 -22z"/>
<path d="M4215 3388 c-3 -13 -16 -81 -29 -151 l-23 -129 83 4 c71 3 86 6 109
28 15 14 31 32 35 41 14 25 32 151 25 177 -8 32 -60 52 -134 52 -55 0 -61 -2
-66 -22z m119 -51 c15 -11 17 -22 11 -71 -5 -36 -15 -66 -26 -77 -19 -19 -64
-26 -72 -11 -5 8 19 148 29 163 8 13 37 11 58 -4z"/>
<path d="M4521 3387 c-5 -14 -24 -64 -41 -113 -18 -49 -38 -106 -46 -126 l-13
-38 35 0 c24 0 36 5 40 18 12 38 22 47 55 47 31 0 34 -3 37 -33 3 -31 6 -33
35 -30 l32 3 0 145 0 145 -63 3 c-58 3 -63 1 -71 -21z m74 -57 c3 -6 3 -30 -1
-55 -6 -42 -8 -45 -36 -45 -22 0 -29 4 -25 14 3 8 11 32 18 55 12 39 31 53 44
31z"/>
<path d="M4715 3395 c-3 -8 -7 -22 -10 -30 -3 -12 5 -15 40 -15 24 0 47 -4 50
-10 3 -5 -1 -50 -9 -100 -22 -126 -22 -130 13 -130 28 0 29 1 45 88 9 48 16
96 16 107 0 32 20 45 66 45 42 0 54 11 54 46 0 21 -259 21 -265 -1z"/>
<path d="M5029 3383 c-5 -16 -12 -35 -15 -43 -3 -8 -23 -63 -45 -122 l-38
-108 32 0 c28 0 36 5 48 33 12 27 19 32 49 32 32 0 35 -3 38 -32 3 -30 6 -33
38 -33 l35 0 -2 137 c0 75 -3 143 -6 150 -3 9 -25 13 -64 13 -56 0 -60 -2 -70
-27z m75 -52 c3 -5 3 -30 0 -55 -6 -42 -9 -46 -35 -46 -16 0 -29 1 -29 3 0 2
7 27 15 55 15 48 35 66 49 43z"/>
<path d="M1350 3368 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M2211 3353 c0 -11 3 -13 6 -5 2 6 10 9 15 6 7 -4 8 -2 4 4 -11 18
-26 14 -25 -5z"/>
<path d="M2227 3295 c10 -70 20 -84 21 -28 0 29 -4 51 -8 48 -5 -3 -11 1 -13
7 -3 7 -3 -5 0 -27z"/>
<path d="M1343 3275 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M2218 3183 c0 -21 -2 -49 -3 -63 -2 -28 -28 -80 -41 -83 -5 -1 -8 -5
-7 -9 7 -19 -150 -172 -164 -161 -2 2 -19 -6 -38 -17 -20 -11 -46 -20 -60 -20
-13 0 -27 -5 -31 -11 -3 -6 -15 -9 -25 -6 -11 3 -19 1 -19 -4 0 -20 65 -5 158
38 56 25 69 35 124 87 83 81 108 130 115 224 2 34 1 62 -2 62 -3 0 -6 -17 -7
-37z"/>
<path d="M1344 3181 c-5 -4 -29 -13 -54 -20 -78 -22 -131 -52 -191 -112 -228
-222 -131 -602 180 -704 86 -28 208 -18 292 24 107 54 179 132 219 238 35 94
34 257 -1 202 -8 -12 -11 -12 -19 1 -5 8 -10 10 -10 4 0 -14 -52 -6 -63 10 -6
8 -10 8 -13 -1 -3 -7 -12 -9 -20 -6 -7 3 -11 10 -7 16 4 6 0 8 -10 4 -8 -3
-21 1 -27 8 -7 8 -16 12 -21 9 -11 -7 -51 21 -42 29 3 4 -1 7 -10 7 -9 0 -17
5 -17 10 0 6 -5 10 -11 10 -13 0 -44 30 -44 44 0 6 -8 10 -17 10 -11 -1 -14 4
-11 14 4 10 2 13 -5 8 -8 -5 -12 0 -12 13 0 11 -11 32 -25 47 -16 18 -25 39
-25 60 0 21 -5 33 -12 32 -6 -2 -13 6 -15 17 -3 16 -2 17 6 5 9 -13 11 -13 11
2 0 20 -13 30 -26 19z"/>
<path d="M3390 2895 c0 -46 16 -63 22 -22 4 31 22 26 28 -9 3 -18 8 -25 13
-17 13 21 -3 63 -24 63 -10 0 -19 7 -19 15 0 8 -4 15 -10 15 -5 0 -10 -20 -10
-45z"/>
<path d="M4210 2926 c0 -8 -10 -16 -22 -18 -17 -2 -23 -10 -23 -28 0 -21 5
-26 33 -28 32 -3 32 -3 32 42 0 25 -4 46 -10 46 -5 0 -10 -6 -10 -14z m0 -52
c0 -8 -7 -14 -15 -14 -15 0 -21 21 -9 33 10 9 24 -2 24 -19z"/>
<path d="M4496 2923 c-11 -11 -7 -48 7 -68 9 -11 16 -14 21 -7 3 6 2 13 -4 17
-5 3 -7 17 -4 30 6 22 -7 41 -20 28z"/>
<path d="M2357 2890 c-8 -30 32 -65 48 -41 3 5 -3 12 -12 14 -13 3 -12 5 5 6
33 1 25 35 -9 39 -20 2 -28 -2 -32 -18z m43 0 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2520 2880 c0 -35 17 -41 22 -7 4 31 22 26 29 -9 6 -28 7 -27 8 9 1
35 -1 37 -29 37 -27 0 -30 -3 -30 -30z"/>
<path d="M2680 2876 c0 -19 -3 -42 -6 -50 -5 -12 -2 -15 10 -10 11 4 16 19 16
50 0 24 -4 44 -10 44 -5 0 -10 -15 -10 -34z"/>
<path d="M2803 2894 c-6 -17 6 -45 23 -51 21 -7 47 23 40 45 -9 27 -53 30 -63
6z m39 -26 c-15 -15 -26 -4 -18 18 5 13 9 15 18 6 9 -9 9 -15 0 -24z"/>
<path d="M2967 2888 c4 -13 7 -35 7 -50 -1 -34 14 -26 32 18 17 39 18 54 4 54
-5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 -5 15 -11 15
-6 0 -7 -8 -2 -22z"/>
<path d="M3236 2894 c-3 -9 -6 -20 -6 -24 0 -14 39 -32 52 -24 7 5 3 10 -11
16 -25 9 -23 35 3 30 9 -2 16 2 16 8 0 16 -47 12 -54 -6z"/>
<path d="M3557 2890 c-8 -30 32 -65 48 -41 3 5 -3 12 -12 14 -13 3 -12 5 5 6
33 1 25 35 -9 39 -20 2 -28 -2 -32 -18z m43 0 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M3743 2904 c24 -7 21 -24 -4 -24 -13 0 -19 -5 -17 -16 2 -8 6 -15 11
-15 4 1 16 1 28 1 16 0 20 5 17 28 -2 21 -9 27 -28 29 -14 1 -18 0 -7 -3z"/>
<path d="M3880 2866 c0 -25 5 -46 10 -46 6 0 10 6 10 14 0 8 10 16 23 18 16 2
22 10 22 28 0 21 -5 26 -32 28 -33 3 -33 3 -33 -42z m45 14 c0 -7 -6 -15 -12
-17 -8 -3 -13 4 -13 17 0 13 5 20 13 18 6 -3 12 -11 12 -18z"/>
<path d="M4355 2896 c20 -15 20 -15 -3 -16 -16 0 -22 -5 -20 -16 2 -8 6 -15
11 -15 4 1 16 1 28 1 16 0 20 5 17 28 -2 20 -9 28 -28 30 -24 3 -25 3 -5 -12z"/>
<path d="M4630 2900 c0 -5 7 -7 15 -4 8 4 15 1 15 -5 0 -6 -6 -11 -14 -11 -20
0 -31 -21 -17 -30 7 -4 22 -5 35 -3 18 4 22 10 18 34 -3 23 -9 29 -28 29 -13
0 -24 -4 -24 -10z"/>
<path d="M1960 2670 c-12 -7 -3 -42 54 -217 37 -114 76 -216 86 -227 18 -18
23 -17 252 53 128 39 260 80 293 90 33 11 130 40 215 66 348 105 476 147 487
158 18 18 -12 61 -36 52 -9 -4 -167 -53 -351 -110 -184 -57 -438 -136 -564
-175 -126 -39 -237 -68 -247 -65 -11 4 -23 23 -30 48 -16 60 -68 223 -91 285
-20 52 -36 63 -68 42z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
